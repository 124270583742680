<template>
  <div></div>
</template>

<script>
export default {
  name: 'deparmentManage'
}
</script>

<style lang="scss">
</style>
